@use '~/ui/styles/breakpoints.module';
@use '~/ui/styles/color.module';
@use '~/ui/styles/typography.module';
@use '~/v1/components/navigation/navigation.constants.module.scss' as navigation;

.page {
  position: relative;
}

.main {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  // vw units don't take scrollbar width into account, causing horizontal overflow
  width: 100%;
  max-width: 100vw;
  min-height: calc(100 * var(--vh));
  min-height: 100svh;
  padding-top: calc(
    #{navigation.$primary-mobile-height} - #{navigation.$primary-sticky-mobile-height}
  );

  &Homepage {
    padding-top: 0;
  }

  @media (min-width: breakpoints.$md) {
    padding-top: calc(
      #{navigation.$primary-tablet-height} - #{navigation.$primary-sticky-tablet-height}
    );
  }

  @media (min-width: breakpoints.$lg) {
    flex-direction: row;
    min-height: calc(100 * var(--vh));
    padding-top: 0;
  }
}

.nav {
  width: 100vw;
  height: 10rem;
  background-color: var(--theme-primary);

  @media (min-width: breakpoints.$lg) {
    width: 20%;
    height: auto;
    min-height: calc(100 * var(--vh));
  }
}

.content {
  position: relative;
  flex: 1;

  @media (min-width: breakpoints.$lg) {
    width: 80%;
  }
}

.draftBanner {
  position: fixed;
  z-index: 1000;
  inset: 0 0 auto;

  padding: 0.5em;

  font-size: 1.4rem;
  text-align: center;
}
