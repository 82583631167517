@use './breakpoints.module';

$font-family-serif: Arial, Helvetica, serif;
$font-halyard-display-regular: 'Halyard Display Regular';
$font-halyard-display-light: 'Halyard Display Light';
$font-halyard-display-medium: 'Halyard Display Medium';
$font-halyard-text-medium: 'Halyard Text Medium';
$font-halyard-text-book: 'Halyard Text Book';
$font-joane-light: 'Joane T Light';
$font-joane-regular: 'Joane T Regular';

$paragraph-line-height: 1.6;
$paragraph-size: 1.6rem;

// while I would _much_ prefer to just use @extend, sass doesn't support extending classes in media queries.
// we have to use mixins in other sass files and classes for imports into React.

@mixin bodyLarge {
  font-family: $font-halyard-display-regular;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0;
}

.bodyLarge {
  @include bodyLarge;
}

@mixin bodySmall {
  font-family: $font-halyard-text-book;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0;
}

.bodySmall {
  @include bodySmall;
}

@mixin paragraph {
  font-family: $font-halyard-text-book;
  font-size: $paragraph-size;
  font-weight: 300;
  line-height: $paragraph-line-height;
  letter-spacing: 0;
}

.paragraph {
  @include paragraph;
}

@mixin linkLarge {
  font-family: $font-halyard-display-regular;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
}

.linkLarge {
  @include linkLarge;
}

@mixin link {
  font-family: $font-halyard-text-medium;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}

.link {
  @include link;
}

@mixin button {
  font-family: $font-halyard-text-medium;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}

.button {
  @include button;
}

@mixin caption {
  font-family: $font-halyard-text-medium;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0;
}

.caption {
  @include caption;
}

// Breakpoint styles
@mixin pageTitleLarge {
  font-family: $font-joane-regular;
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 1.1;
  letter-spacing: -0.01em;

  @media (min-width: breakpoints.$md) {
    font-size: 6.4rem;
    letter-spacing: -0.02em;
  }

  @media (min-width: breakpoints.$lg) {
    font-size: 8.2rem;
  }
}

.pageTitleLarge {
  @include pageTitleLarge;
}

@mixin pageTitleSmall {
  font-family: $font-joane-regular;
  font-size: 4rem;
  font-weight: 400;
  line-height: 1.1;
  letter-spacing: -0.01em;

  @media (min-width: breakpoints.$md) {
    font-size: 5rem;
    letter-spacing: -0.02em;
  }

  @media (min-width: breakpoints.$lg) {
    font-size: 6.4rem;
  }
}

.pageTitleSmall {
  @include pageTitleSmall;
}

@mixin sectionTitle {
  font-family: $font-halyard-display-regular;
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 1.1;
  letter-spacing: 0;

  @media (min-width: breakpoints.$md) {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.15;
  }

  @media (min-width: breakpoints.$lg) {
    font-size: 4.6rem;
  }
}

.sectionTitle {
  @include sectionTitle;
}

@mixin modulesTitle {
  font-family: $font-halyard-display-regular;
  font-size: 2.6rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0;

  @media (min-width: breakpoints.$lg) {
    font-size: 3.2rem;
  }
}

.modulesTitle {
  @include modulesTitle;
}

@mixin blockquote {
  font-family: $font-joane-light;
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0;

  @media (min-width: breakpoints.$md) {
    font-size: 3.2rem;
  }

  @media (min-width: breakpoints.$lg) {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.15;
  }
}

.blockquote {
  @include blockquote;
}

@mixin dropCap {
  &::first-letter {
    float: left;

    margin-right: 0.4rem;

    font-family: $font-joane-regular;
    font-size: 8.8rem;
    // it should be the height of 3 lines - we set it to 2.95 to prevent it breaking into 4 lines because of rounding
    line-height: $paragraph-line-height * $paragraph-size * 2.95;
    letter-spacing: -0.02rem;

    // used to align it on firefox
    @supports (-moz-appearance: none) {
      margin-top: 0.7rem;
    }
  }
}

.dropCap {
  @include dropCap;
}
